import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { tradWithColors } from '@/utils/trad';

import LinkItem from '../LinkItem';
import MouseEffectImg, { MouseEffectImgProps } from '../MouseEffectImg';

interface CtaBlockProps {
  title: string;
  linkTitle: string;
  to?: string;
  onClick?: () => void;
  external?: boolean;
  className?: string;
  emoji: MouseEffectImgProps;
}

const CtaBlock: React.FC<CtaBlockProps> = ({
  title,
  linkTitle,
  to,
  onClick,
  external,
  emoji,
  className,
}) => {
  const coloredTitle = useMemo(() => tradWithColors(title), [title]);

  const content = useMemo(
    () => (
      <div className="max-w-main flex justify-between flex-col lg:flex-row">
        <MouseEffectImg {...emoji} className="w-1/2 lg:w-auto lg:order-2" />
        <div className="lg:w-2/3 lg:flex lg:flex-col lg:justify-center">
          <div className="font-heading text-4xl mb-5 lg:mb-10 lg:text-6xl">
            {coloredTitle}
          </div>
          <LinkItem
            className="font-heading text-2xl"
            title={linkTitle}
            to={to}
            alone={false}
          />
        </div>
      </div>
    ),
    [coloredTitle, emoji, linkTitle, to],
  );

  const classes = useMemo(
    () =>
      classNames(
        'group bg-mainDark dark:bg-primaryLighter text-white dark:text-mainDark flex flex-col p-[42px] pr-[50px] rounded-main gap-5 justify-between transition-all duration-500',
        'lg:flex-row lg:px-[100px] 2xl:px-[170px] lg:py-[80px] lg:items-center lg:justify-center',
        'hover:-translate-y-[1%] hover:shadow-cbig',
        className,
      ),
    [className],
  );

  if (to) {
    if (external) {
      return (
        <a href={to} className={classes}>
          {content}
        </a>
      );
    }

    return (
      <Link to={to} className={classes}>
        {content}
      </Link>
    );
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={onClick}
      className={classNames(
        'group bg-mainDark dark:bg-primaryLighter text-white dark:text-mainDark flex flex-col p-[42px] pr-[50px] rounded-main gap-5 justify-between transition-all duration-500',
        'lg:flex-row lg:px-[170px] lg:py-[80px] lg:items-center',
        'hover:-translate-y-[1%] hover:shadow-cbig',
        className,
      )}
    >
      <MouseEffectImg {...emoji} className="w-1/2 lg:w-auto lg:order-2" />
      <div className="lg:w-2/3">
        <div className="font-heading text-4xl mb-5 lg:mb-10 lg:text-6xl">
          {coloredTitle}
        </div>
        <LinkItem
          className="font-heading text-2xl lg:text-5xl"
          title={linkTitle}
          to={to}
          alone={false}
        />
      </div>
    </div>
  );
};

export default CtaBlock;
