import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';

const colors = {
  _violet_: 'text-primaryLighter',
  _yellow_: 'text-yellow',
  _orange_: 'text-orange',
  _green_: 'text-green',
  _primary_: 'text-primary',
};

export const tradWithColors = (text: string, className?: string) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{
      __html: Object.keys(colors).reduce(
        (acc, key) => acc.replaceAll(key, `class="${colors[key]}"`),
        text,
      ),
    }}
  />
);

export const tradWithLink = (
  tradKey: string,
  linkLabel: string,
  linkHref: string,
) => {
  const { t } = useTranslation();
  const splittedText = t(tradKey).split('{{link}}');

  return (
    <span>
      {splittedText[0]}
      {linkHref.startsWith('https') ? (
        <a
          className="simpleLink"
          href={linkHref}
          rel="noreferrer"
          target="_blank"
        >
          {linkLabel}
        </a>
      ) : (
        <Link className="simpleLink" to={linkHref}>
          {linkLabel}
        </Link>
      )}
      {splittedText[1]}
    </span>
  );
};
